/**
 * Needs to be placed in the <head>, and before loading Alpine itself.
 *
 * Also, pull in AlpineJS 3.* at the end of the layout.
 */
document.addEventListener("alpine:init", () => {
  Alpine.data("reviewerBioModel", (reviewerId, bioScriptId) => {
    let bio = JSON.parse(document.getElementById(bioScriptId).innerText)

    return {
      reviewerId: reviewerId,
      content: bio.bio || "",
      saving: false,
      updated: false,
      show: false,

      save() {
        this.updated = false
        this.saving = true

        let data = { bio: this.content }
        fetch(`/profile/${this.reviewerId}/update`, {
          method: "POST",
          mode: "same-origin",
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" }
        })
          .then((result) => {
            if (result.ok) {
              this.updated = true
              this.saving = false

              window.location.reload()
            } else {
              this.updated = false
              this.saving = false
            }
          })
          .catch((err) => {
            console.warn(err)
            this.updated = false
            this.saving = false
          })
      }
    }
  })

  /**
   * A model for reviews which may or may not belong to the current user.
   *
   * The reviewer id and current user id are both ORCiD ids.
   * They are not necessarily the same...
   */
  Alpine.data("reviewModel", (urlId, reviewId, reviewerId, currentUserId) => {
    const isMine =
      typeof currentUserId !== "undefined" && currentUserId === reviewerId

    return {
      reviewId: reviewId,
      urlId: urlId,
      isMine: isMine,
      reported: false,

      // flag this naughty comment
      report: function () {
        if (this.isMine) {
          console.warn(
            `Attempting to report a review, but it's mine: ${this.urlId}, ${this.isMine}`
          )
          return false
        }

        fetch(`/review/${this.urlId}/${this.reviewId}/report`, {
          method: "POST"
        })
          .then((resp) => resp.json())
          .then((_json) => {
            // json is a ReportCommentResponse
            // we could show how many have reported this comment here
            this.reported = true
          })
          .catch((err) => console.warn(err))
      },

      // delete this review if it's mine
      del: function () {
        if (!this.isMine) {
          console.warn(
            `Attempting to delete a review, but it isn't mine: ${this.urlId}, ${this.isMine}`
          )
          return false
        }

        const confirmed = window.confirm("Really remove this review?")
        if (confirmed) {
          fetch(`/review/${this.urlId}/${this.reviewId}`, {
            method: "DELETE"
          })
            .then((resp) => resp.json())
            .then((json) => {
              console.log("delete review", this.reviewId, json)
              window.location.replace("/dashboard")
            })
            .catch((err) => console.warn("delete my review", err))
        }
      }
    }
  })

  Alpine.data("localModel", (scriptId) =>
    JSON.parse(document.getElementById(scriptId).innerText)
  )
})
